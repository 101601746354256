import React from 'react';
import './App.css';
import Footer from "./Footer";
import EmailCapture from "./EmailCapture";
import Spacer from "./Spacer";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={`${process.env.PUBLIC_URL}/laneandsons.png`} className="App-logo" alt="logo" />
                <div className="App-info">
                <p><b>Fine software hand-crafted by artisans.</b> For generations, the Lane family has passed down the tradition of excellence and precision in every line of code. At Lane & Sons Digital Wares Inc., our legacy of quality craftsmanship is woven into every project. As we continue to grow and innovate, we invite you to join our family tradition. Stay tuned – something special is on the horizon.</p>
                </div>
            </header>
<main className="App-main">
            <EmailCapture />
</main>
        <footer className="App-footer">
            <Footer />
        </footer>
        </div>
    );
}

export default App;

import React, { useState } from 'react';
import './EmailCapture.css'; // Import the CSS file

const EmailCapture = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateEmail(email)) {
            console.log('Email submitted:', email);
            setError('');
        } else {
            setError('Please enter a valid email address.');
        }
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <div className="email-capture-container">
            <form onSubmit={handleSubmit} className="email-capture-form">
                <label htmlFor="email" className="email-capture-label">Email:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleChange}
                    className="email-capture-input"
                />
                <button type="submit" className="email-capture-button">Submit</button>
                {error && <p className="email-capture-error">{error}</p>}
            </form>
        </div>
    );
};

export default EmailCapture;
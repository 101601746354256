import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="App-footer">
            <p>&copy; {new Date().getFullYear()} Lane & Sons Digital Wares LLC. All rights reserved.</p>
            <nav>
                {/* Future directory links can be added here */}
            </nav>
        </footer>
    );
}

export default Footer;
